/* global AnsiLove */
import React, { useEffect, useState } from 'react';

const Home = () => {
  const [ansiArrayBuffer, setAnsiArrayBuffer] = useState(null);

  // Dynamically load the ansilove.js script
  useEffect(() => {
    const script = document.createElement('script');
    script.src = '/js/ansilove.js';
    script.async = true;

    script.onload = () => {
      console.log('ansilove.js script loaded');

      // Fetch the ANSI file as an ArrayBuffer
      fetch('/assets/altama_bbs_graphic.ans')
        .then(response => response.arrayBuffer())
        .then(arrayBuffer => {
          console.log('Fetched ANSI file as ArrayBuffer:', arrayBuffer);
          setAnsiArrayBuffer(arrayBuffer);
        })
        .catch(error => {
          console.log('Error fetching ANSI file:', error);
        });
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  // Render the ANSI art
  useEffect(() => {
    if (ansiArrayBuffer && window.AnsiLove) {
      const blob = new Blob([ansiArrayBuffer], { type: 'application/octet-stream' });
      const blobUrl = URL.createObjectURL(blob);

      // Using the AnsiLove library to render the ANSI art
      AnsiLove.render(blobUrl, function (canvas, sauce) {
        document.getElementById('ansi-container').appendChild(canvas);
        console.log('Callback sauce object:', sauce);
      }, { "bits": "8" });
    }
  }, [ansiArrayBuffer]);

  return (
    <div>
      <div id="ansi-container"></div>
    </div>
  );
};

export default Home;
